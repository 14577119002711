import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import VehiclePlate, { IVehiclePlateProps } from '../screens/VehiclePlate/VehiclePlate';

const VehiclePlateContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: IVehiclePlateProps = {
    actions,
    vehicleInfo: phoenixRoot.vehicleInfo as IVehiclePlateProps['vehicleInfo'],
    progress: phoenixRoot.progress as IVehiclePlateProps['progress'],
  };

  return <VehiclePlate {...props} />;
};

export default VehiclePlateContainer;
