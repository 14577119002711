import styled from 'styled-components';
import { Card } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Header from '../../components/Header/Header';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import contactIcon from '../../assets/contact.svg';
import { toDefaultPhoneFormat } from '../../utils/stringFormat';
import ContainerFooter from '../../components/ContainerFooter/ContainerFooter';
import { AppActions } from '../../store/configureStore';
import { useRedirectToScreen } from '../../utils/useRedirectToScreen';
import { useTranslation } from 'react-i18next';
import useEffectOnce from '../../utils/useEffectOnce';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';

type Contacts = {
  results: {
    contactId: string;
    email: string;
    cellPhone: string;
    activeLeads: {
      leadId: string;
      leadType: string;
    }[];
  };
};

type Progress = {
  data: {
    nextScreen: string;
    vinNumber: string;
    assetId: string;
    isVinEntered: boolean;
    lastCompletedScreen: string;
  };
};

export type Props = {
  contacts: Contacts;
  actions: AppActions;
  progress?: Progress;
  creditSummary: {
    ficoDetails?: { name: string; scoreType: string; score: string }[];
    loanDetails?: {
      lender: string;
      currentBalance: string;
      openDate: string;
      payment: string;
      remainingTerm: string;
    }[];
  };
  estimatedOffers: {
    data?: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    }[];
    errors?: { [key: string]: string };
  };
  vehicleInfo: {
    data: { [key: string]: string };
    errors: { [key: string]: string };
    loading: boolean;
  };
  address: {
    address: '',
    aptNumber: '',
    city: '',
    state: '',
    zipCode: '',
    moveinDate: '',
    residenceType: '',
    monthlyPayment: '',
    contactId: '',
  };
  estimatedOfferSelected: {
    data?: {
      dealId: string;
      rate: number;
      monthlyPaymentAmount: number;
      term: number;
      offerType: string;
    };
    errors?: { [key: string]: string };
  };
};

const ContactCard = styled(Card)(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  height: '110px',
  backgroundColor: props.theme.palette.lighterGray.main,
  placeItems: 'center',
  marginTop: '15px',
  marginBottom: '10px',
}));

const ContactIconButton = styled.span(props => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  borderRadius: '50%',
  marginLeft: '17px',
  backgroundColor: props.theme.palette.white.main,
  height: '58px',
  width: '58px',
}));

const ContactIcon = styled.img(props => ({
  color: props.theme.palette.teal.main,
  width: '45%',
  pointerEvents: 'none',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const ContactCardText = styled(Typography)(() => ({
  marginLeft: '15px',
}));

const ActiveLead = ({
  contacts: leadInfo,
  progress,
  actions,
  creditSummary,
  estimatedOffers,
  estimatedOfferSelected,
  vehicleInfo,
  address,
}: Props) => {
  const isNonDigital = leadInfo.results?.activeLeads?.some(lead => lead.leadType === 'NonDigital');
  const { t } = useTranslation();
  useRedirectToScreen({
    data: progress?.data,
    actions,
    creditSummary,
    estimatedOffers,
    estimatedOfferSelected,
    vehicleInfo,
  });

  let content;
  if (isNonDigital) {
    content = (
      <>
        <Typography variant="body2" sx={{ marginTop: '15px', marginBottom: '15px' }}>
          {t('activeLead.nonDigital.contentText')}
          <span style={{ color: '#2C5A7A', fontSize: '16px', fontWeight: 400 }}>
            {t('contactPhoneNumber')}
          </span>
        </Typography>
        <ContainerFooter>
          <Button
            variant="contained"
            color="yellow"
            fullWidth
            href={'tel:' + t('contactPhoneNumber').replace(/-/g, '')}
          >
            {t('activeLead.nonDigital.buttonText')} {t('contactPhoneNumber')}
          </Button>
        </ContainerFooter>
      </>
    );
  } else {
    content = (
      <>
        <Typography variant="body2" sx={{ marginTop: '15px', marginBottom: '15px' }}>
          {t('activeLead.digital.contentText')}
        </Typography>
        <ContainerFooter>
          <Button
            variant="contained"
            color="yellow"
            fullWidth
            onClick={() => actions.phoenixGetProgressHistory()}
          >
            {t('activeLead.digital.buttonText')}
          </Button>
        </ContainerFooter>
      </>
    );
  }

  useEffectOnce(() =>
    actions.analyticsTrackPage(isNonDigital ? 'ActiveLeadNonDigital' : 'ActiveLeadDigital')
  );

  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('activeLead.title')}</Typography>
        <ContactCard>
          <ContactIconButton>
            <ContactIcon src={contactIcon} />
          </ContactIconButton>
          <ContactCardText>
            {leadInfo.results?.email}
            <br />
            {toDefaultPhoneFormat(leadInfo.results?.cellPhone)}
          </ContactCardText>
        </ContactCard>
        {content}
      </ScreenContainer>
      <RecaptchaLogo />
    </>
  );
};

export default ActiveLead;
