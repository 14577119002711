import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header/Header';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import CurrentAddressFormContainer from '../../containers/CurrentAddressFormContainer';

import { Card } from '@mui/material';
import contactIcon from '../../assets/contact.svg';

const ContactCard = styled(Card)(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  height: '110px',
  backgroundColor: props.theme.palette.lighterGray.main,
  placeItems: 'center',
  marginTop: '15px',
  marginBottom: '10px',
}));

const ContactIconButton = styled.span(props => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  borderRadius: '50%',
  marginLeft: '17px',
  backgroundColor: props.theme.palette.white.main,
  height: '58px',
  width: '58px',
}));

const ContactIcon = styled.img(props => ({
  color: props.theme.palette.teal.main,
  width: '45%',
  pointerEvents: 'none',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const ContactCardText = styled(Typography)(() => ({
  marginLeft: '15px',
}));

const CurrentAddress = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('addressForm.moreInfo')}</Typography>
        <ContactCard>
          <ContactIconButton>
            <ContactIcon src={contactIcon} />
          </ContactIconButton>
          <ContactCardText>
            Address value from API goes here
            <br />
          </ContactCardText>
        </ContactCard>
        <CurrentAddressFormContainer />
      </ScreenContainer>
    </>
  );
};


export default CurrentAddress;
