import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Header from "../../components/Header/Header";
import ScreenContainer from "../../components/ScreenContainer/ScreenContainer";
import { useTranslation } from "react-i18next";
import ContainerFooter from "../../components/ContainerFooter/ContainerFooter";
import CustomButton from "../../components/CustomButton/CustomButton";
import { countryStates } from "../../shared/constants/countryStates";
import { VehicleVinGuide } from "../../components/VehicleInfoForm/VehicleInfoForm";
import { usePostProgressHistory } from "../../utils/usePostProgressHistory";
import { AppActions } from "../../store/configureStore";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { postPlateToVinData } from "../../services/phoenix";
import { useGetVehicleInfo } from "../../utils/useGetVehicleInfo";
import VehiclePlateValidation from "../../schemas/VehiclePlateValidation";

export interface IStaticNav {
  nextScreen: string;
  navigateTo: string;
}

export type IVehiclePlateProps = {
  actions: AppActions;
  state?: {
    isReEnterVin?: boolean
  },
  vehicleInfo: {
    data: { [key: string]: string };
    errors: { [key: string]: string };
    loading: boolean;
  },
  progress?: {
    data: {
      completedScreen: string;
      nextScreen: string;
    };
  };
}

const initialValues: postPlateToVinData = {
  leadId: '',
  plateState: '',
  plateNumber: '',
  assetId: '',
  contactId: ''
}

const VehiclePlate = ({ actions, vehicleInfo, progress }: IVehiclePlateProps) => {
  const { t } = useTranslation();
  const [initValues, setStaticNav] = useState<IStaticNav>({
    navigateTo : '',
    nextScreen: '',
  });

  const {getVehicleInfoFromPlate} = useGetVehicleInfo({
    actions,
    vehicleInfo,
  });

  const [postProgressHistory] = usePostProgressHistory({
    actions,
    progress: progress?.data,
    screen: 'PlateToVIN',
    nextScreen: initValues.nextScreen,
    navigateTo: initValues.navigateTo,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: VehiclePlateValidation,
      onSubmit: values => {
        actions.analyticsTrackFormSubmit({ eventType: 'PlateToVin Form Submit', ...values });
        getVehicleInfoFromPlate(values);
      },
      validateOnMount: true,
    });

    useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if(initValues.nextScreen && initValues.navigateTo) {
        postProgressHistory();
      }      
    }, [initValues, postProgressHistory]);

    const EnterManually = () => {
      setStaticNav({
        nextScreen : 'VehicleNoVin',
        navigateTo :'/vehicle-no-vin'
      });
      postProgressHistory();
      actions.analyticsTrackClick({ eventType: "Enter vehicle info manually clicked" });
    }

    return (
      <>
        <Header />
        <ScreenContainer>
          <Typography variant="h1">{t('vehiclePlate.title')}</Typography><p></p>
          <form name="vehcilePlateForm" onSubmit={handleSubmit} >
            <Stack spacing={2}>
            <FormControl
                variant="filled"
                error={touched.plateNumber && !!errors.plateNumber}
                sx={{
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: 'darkGray.main',
                  },
                }}
              >
              <TextField
                id="plateNumber"
                name="plateNumber"
                label={t('vehiclePlate.form.plateNumber')}
                variant="filled"
                value={values.plateNumber?.toUpperCase()}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  sx: {
                    bgcolor: 'lighterGray.main',
                  },
                }}
                sx={{
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: 'darkGray.main',
                  },
                }}
              />
              <FormHelperText>{touched.plateNumber && errors.plateNumber}</FormHelperText>
              </FormControl>

              <FormControl
                variant="filled"
                error={touched.plateState && !!errors.plateState}
                sx={{
                  '& .MuiFilledInput-underline:before': {
                    borderBottomColor: 'darkGray.main',
                  },
                }}
              >
                <InputLabel id="plateState">{t('vehiclePlate.form.plateState')}</InputLabel>
                <Select
                  id="plateState"
                  name="plateState"
                  value={values.plateState}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="plateState"
                  sx={{
                    bgcolor: 'lighterGray.main',
                  }}
                >
                  {countryStates.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{touched.plateState && errors.plateState}</FormHelperText>
              </FormControl>
            </Stack>

            <ContainerFooter>
              <VehicleVinGuide onClick={EnterManually} >{t('vehiclePlate.form.enterManually')}</VehicleVinGuide>
              <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
                {t('vehiclePlate.form.continue')}
              </CustomButton>
            </ContainerFooter>
          </form>
        </ScreenContainer>
      </>
    );
}

export default VehiclePlate;