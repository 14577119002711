import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import VehicleIdForm, { Props } from '../components/VehicleIdForm/VehicleIdForm';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const VehicleIdFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    actions,
    progress: phoenixRoot.progress as Props['progress'],
  };

  return <VehicleIdForm {...props} />;
};

export default VehicleIdFormContainer;
