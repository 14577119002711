import { fromJS, Map, is } from 'immutable';
import { defaultMemoize, createSelectorCreator } from 'reselect';
import { Action, handleActions } from 'redux-actions';

import { RootStateImmutable, RootState, PayloadAction } from '../configureStore';
import ActionTypes from '../../actions/actionTypes';
import { AnyAction } from 'redux';

export const initialState = Map<string, unknown>(
  fromJS({
    contacts: { errors: null, results: null },
    qualifier: { isDisqualified: false },
    vehicleInfoOptions: { years: [], makes: [], models: [], errors: null },
    vehicleInfo: { data: null, errors: null, loading: false },
    address: { errors: null, results: null },
    ssn: { data: null, errors: null },
    creditSummary: { errors: null },
    creditScore: { errors: null },
    asset: { data: null, errors: null },
    estimatedOffers: { data: null, errors: null },
    estimatedOfferSelected: { data: null, errors: null },
    progress: { data: null, errors: null },
    selectedAsset: { data: null, errors: null },
  })
);

const setAssetSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['selectedAsset', 'data'], payload).setIn(['selectedAsset', 'errors'], null);

const setAssetError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['selectedAsset', 'errors'], payload || 'error');

const setContactError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['contacts', 'errors'], payload || 'error');

const setContactSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['contacts', 'results'], payload).setIn(['contacts', 'errors'], null);

const setQualifierSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['qualifier', 'isDisqualified'], payload);

const setLeadError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['contacts', 'errors'], payload || 'error');

const setLeadSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.mergeIn(['contacts', 'results'], payload).setIn(['contacts', 'errors'], null);

const updateSSNError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['ssn', 'errors'], payload || 'error');

const updateSSNSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['ssn', 'data'], payload).setIn(['ssn', 'errors'], null);

const setCreditSummaryError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['creditSummary', 'errors'], payload || 'error');

const setCreditSummarySuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.mergeIn(['creditSummary'], payload).setIn(['creditSummary', 'errors'], null);

const setCreditScoreError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['creditScore', 'errors'], payload || 'error');

const setCreditScoreSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.mergeIn(['creditScore'], payload).setIn(['creditScore', 'errors'], null);

const setYearsError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['vehicleInfoOptions', 'errors'], payload || 'error');

const setYearsSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state
    .setIn(['vehicleInfoOptions', 'years'], payload)
    .setIn(['vehicleInfoOptions', 'makes'], [])
    .setIn(['vehicleInfoOptions', 'models'], [])
    .setIn(['vehicleInfoOptions', 'errors'], null);

const setMakesError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['vehicleInfoOptions', 'errors'], payload || 'error');

const setMakesSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state
    .setIn(['vehicleInfoOptions', 'makes'], payload)
    .setIn(['vehicleInfoOptions', 'models'], [])
    .setIn(['vehicleInfoOptions', 'errors'], null);

const setModelsError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['vehicleInfoOptions', 'errors'], payload || 'error');

const setModelsSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state
    .setIn(['vehicleInfoOptions', 'models'], payload)
    .setIn(['vehicleInfoOptions', 'errors'], null);

const clearVehicleInfo = (state: RootStateImmutable) =>
  state
    .setIn(['vehicleInfo', 'data'], null)
    .setIn(['vehicleInfo', 'errors'], null)
    .setIn(['progress', 'data'], null);

const setVehicleInfoFromVINError = (
  state: RootStateImmutable,
  { payload }: PayloadAction<string>
) => state.setIn(['vehicleInfo', 'errors'], payload || 'error');

const setVehicleInfoFromVINSuccess = (
  state: RootStateImmutable,
  { payload }: PayloadAction<string>
) =>
  state
    .setIn(['vehicleInfo', 'data'], payload)
    .setIn(['vehicleInfo', 'errors'], null)
    .setIn(['progress', 'data'], null);

const setAssetsError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['asset', 'errors'], payload || 'error');

const setAssetsSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['asset', 'data'], payload).setIn(['asset', 'errors'], null);

const setEstimatedOffersError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['estimatedOffers', 'errors'], payload || 'error');

const setEstimatedOffersSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['estimatedOffers', 'data'], payload).setIn(['estimatedOffers', 'errors'], null);

const setEstimatedOfferSelectedError = (
  state: RootStateImmutable,
  { payload }: PayloadAction<string>
) => state.setIn(['estimatedOfferSelected', 'errors'], payload || 'error');

const setEstimatedOfferSelectedSuccess = (
  state: RootStateImmutable,
  { payload }: PayloadAction<string>
) =>
  state
    .setIn(['estimatedOfferSelected', 'data'], payload)
    .setIn(['estimatedOfferSelected', 'errors'], null);

const setAddressSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['address', 'errors'], payload || 'error');

const setAddressError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['address', 'errors'], payload || 'error');

const setCurrentAddressSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['address', 'errors'], payload || 'error');

const setCurrentAddressError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['address', 'errors'], payload || 'error');

const setPlateToVin = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['plateToVin', 'errors'], payload || 'error');

const setPlateToVinSuccess = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state
  .setIn(['vehicleInfo', 'data'], payload || 'error')
  .setIn(['vehicleInfo', 'errors'], null)
  .setIn(['progress', 'data'], null);

const setPlateToVinError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state
  .setIn(['vehicleInfo', 'errors'], payload || 'error');  

const setProgressHistoryError = (state: RootStateImmutable, { payload }: PayloadAction<string>) =>
  state.setIn(['progress', 'errors'], payload || 'error');

const setProgressHistorySuccess = (state: RootStateImmutable, { payload }: AnyAction) =>
  state
    .setIn(['progress', 'data'], payload)
    .setIn(['progress', 'errors'], null)
    .setIn(['contacts', 'results'], state.get('contacts.results', {}))
    .mergeIn(['contacts', 'results'], { leadId: payload.leadId, contactId: payload.contactId })
    .setIn(['asset', 'data'], payload.assetId ? { assetId: payload.assetId } : null)
    .setIn(['asset', 'errors'], null);

const postProgressHistorySuccess = (state: RootStateImmutable, { payload }: AnyAction) =>
  state.setIn(['progress', 'data'], payload).setIn(['progress', 'errors'], null);

const handlers = {
  [ActionTypes.PHOENIX_POST_CONTACT_ERROR]: setContactError,
  [ActionTypes.PHOENIX_POST_CONTACT_SUCCESS]: setContactSuccess,
  [ActionTypes.PHOENIX_POST_QUALIFIERS_SUCCESS]: setQualifierSuccess,
  [ActionTypes.PHOENIX_POST_LEAD_ERROR]: setLeadError,
  [ActionTypes.PHOENIX_POST_LEAD_SUCCESS]: setLeadSuccess,
  [ActionTypes.PHOENIX_UPDATE_SSN_ERROR]: updateSSNError,
  [ActionTypes.PHOENIX_UPDATE_SSN_SUCCESS]: updateSSNSuccess,
  [ActionTypes.PHOENIX_POST_CREDIT_SCORE_ERROR]: setCreditScoreError,
  [ActionTypes.PHOENIX_POST_CREDIT_SCORE_SUCCESS]: setCreditScoreSuccess,
  [ActionTypes.PHOENIX_GET_CREDIT_SUMMARY_SUCCESS]: setCreditSummarySuccess,
  [ActionTypes.PHOENIX_GET_CREDIT_SUMMARY_ERROR]: setCreditSummaryError,
  [ActionTypes.PHOENIX_GET_YEARS_ERROR]: setYearsError,
  [ActionTypes.PHOENIX_GET_YEARS_SUCCESS]: setYearsSuccess,
  [ActionTypes.PHOENIX_GET_MAKES_ERROR]: setMakesError,
  [ActionTypes.PHOENIX_GET_MAKES_SUCCESS]: setMakesSuccess,
  [ActionTypes.PHOENIX_GET_MODELS_ERROR]: setModelsError,
  [ActionTypes.PHOENIX_GET_MODELS_SUCCESS]: setModelsSuccess,
  [ActionTypes.PHOENIX_CLEAR_VEHICLE_INFO]: clearVehicleInfo,
  [ActionTypes.PHOENIX_GET_VEHICLE_INFO_ERROR]: setVehicleInfoFromVINError,
  [ActionTypes.PHOENIX_GET_VEHICLE_INFO_SUCCESS]: setVehicleInfoFromVINSuccess,
  [ActionTypes.PHOENIX_POST_ASSETS_ERROR]: setAssetsError,
  [ActionTypes.PHOENIX_PUT_ASSET_ERROR]: setAssetsError,
  [ActionTypes.PHOENIX_POST_ASSETS_SUCCESS]: setAssetsSuccess,
  [ActionTypes.PHOENIX_PUT_ASSET_SUCCESS]: setAssetsSuccess,
  [ActionTypes.PHOENIX_GET_ESTIMATED_OFFERS_SUCCESS]: setEstimatedOffersSuccess,
  [ActionTypes.PHOENIX_GET_ESTIMATED_OFFERS_ERROR]: setEstimatedOffersError,
  [ActionTypes.PHOENIX_POST_ASSET_ERROR]: setAssetError,
  [ActionTypes.PHOENIX_POST_ASSET_SUCCESS]: setAssetSuccess,
  [ActionTypes.PHOENIX_POST_ESTIMATED_OFFER_SUCCESS]: setEstimatedOfferSelectedSuccess,
  [ActionTypes.PHOENIX_POST_ESTIMATED_OFFER_ERROR]: setEstimatedOfferSelectedError,
  [ActionTypes.PHOENIX_POST_ADDRESS_SUCCESS]: setAddressSuccess,
  [ActionTypes.PHOENIX_POST_ADDRESS_ERROR]: setAddressError,
  [ActionTypes.PHOENIX_POST_ADDRESS_SUCCESS]: setCurrentAddressSuccess,
  [ActionTypes.PHOENIX_POST_ADDRESS_ERROR]: setCurrentAddressError,
  [ActionTypes.PHOENIX_GET_PROGRESS_HISTORY_SUCCESS]: setProgressHistorySuccess,
  [ActionTypes.PHOENIX_GET_PROGRESS_HISTORY_ERROR]: setProgressHistoryError,
  [ActionTypes.PHOENIX_POST_PROGRESS_HISTORY_SUCCESS]: postProgressHistorySuccess,
  [ActionTypes.PHOENIX_POST_PLATE_TO_VIN]: setPlateToVin,
  [ActionTypes.PHOENIX_POST_PLATE_TO_VIN_SUCCESS]: setPlateToVinSuccess,
  [ActionTypes.PHOENIX_POST_PLATE_TO_VIN_ERROR]: setPlateToVinError,
};

const reduce = handleActions(handlers, initialState);

const rehydrate = (state: RootStateImmutable) => initialState.merge({ ...state });

const reducer = (state: RootStateImmutable, action: Action<string>) =>
  Map.isMap(state) || !state ? reduce(state, action) : rehydrate(state);

const getPhoenixRoot = (state: RootState) => state.phoenix;

const createImmutableSelector = createSelectorCreator(defaultMemoize, is);

// @ts-ignore
const phoenixRoot = createImmutableSelector([getPhoenixRoot], (phoenix: RootStateImmutable) =>
  phoenix.toJS()
);

export default reducer;

export const selectors = { phoenixRoot };
