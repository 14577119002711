import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import AboutYouFormContainer from '../../containers/AboutYouFormContainer';
import Header from '../../components/Header/Header';
import TrustBox from '../../components/TrustPilot/TrustPilot';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';

const AboutMe = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('aboutYou.title')}</Typography>
        <p></p>
        {/* <p>{t(['aboutYou.mktgLine1'])}<br/>
        {t(['aboutYou.mktgLine2'])}<br/>
        {t(['aboutYou.mktgLine3'])}</p> */}
        <AboutYouFormContainer />
        <TrustBox />
      </ScreenContainer>
      
      <RecaptchaLogo />
    </>
  );
};

export default AboutMe;
