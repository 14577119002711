import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import AddressHistoryFormContainer from '../../containers/AddressHistoryFormContainer';
import Header from '../../components/Header/Header';
import StepProgress from '../../components/StepProgress/StepProgress';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';

const TooltipInfoIcon = styled(InfoIcon)(props => ({
  color: props.theme.palette.teal.main,
  fontSize: 16,
  alignSelf: 'start',
  marginTop: '2px',
  marginLeft: '-2px',
}));

const AddressHistory = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <ScreenContainer>
        <StepProgress size={4} />
        <Typography variant="h1">
          {t('addressHistoryForm.title')}
          <Tooltip arrow title={t('addressHistoryForm.tooltip')}>
            <IconButton>
              <TooltipInfoIcon />
            </IconButton>
          </Tooltip>
        </Typography>

        <AddressHistoryFormContainer />
      </ScreenContainer>
    </>
  );
};


export default AddressHistory;
