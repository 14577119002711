import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppActions } from '../../store/configureStore';
import { usePostProgressHistory } from '../../utils/usePostProgressHistory';
import CustomButton from '../CustomButton/CustomButton';
import { Box } from '@mui/material';

export interface IInitialValues {
  nextScreen: string;
  navigateTo: string;
}
export interface Props {
  actions: AppActions;
  progress?: {
    data: {
      completedScreen: string;
      nextScreen: string;
    };
  };
}

const VehicleIdForm = (props: Props) => {
  const { actions, progress} = props;
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<IInitialValues>({
    navigateTo : '',
    nextScreen: '',
  });

  const [postProgressHistory] = usePostProgressHistory({  
    actions,
    progress: progress?.data,
    screen: 'VehicleId',
    nextScreen: initialValues.nextScreen,
    navigateTo: initialValues.navigateTo,
    isVinEntered: false
  });

  useEffect(() => {
    if(initialValues.nextScreen && initialValues.navigateTo) {
      postProgressHistory();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const Plate2VIN = () => {
    setInitialValues({
      nextScreen : 'PlateToVIN',
      navigateTo :'/vehicle-plate'
    });
  }

  const EnterVin = () => {
    setInitialValues({
      nextScreen: 'VehicleInfo',
      navigateTo:'/vehicle-info'
    });
  }

  const NoVin = () => {
    setInitialValues({
      nextScreen : 'VehicleNoVin',
      navigateTo :'/vehicle-no-vin'
    });
  }
  return (
    <form>
      <Box>
          <CustomButton type="button" onClick={Plate2VIN} id="Plate2Vin">{t('vehicleId.form.Plate2VIN')}</CustomButton>
          <CustomButton type="button" onClick={EnterVin} id="EnterVin">{t('vehicleId.form.EnterVIN')}</CustomButton>
          <CustomButton type="button" onClick={NoVin} id="NoVin">{t('vehicleId.form.EnterManually')}</CustomButton>
      </Box>
    </form>
  );
};

export default VehicleIdForm;