import * as Yup from 'yup';
import i18next from '../utils/i18n';

const alphaNumericRegex = /^.[a-zA-Z0-9]+$/;
const schema = () =>
  Yup.object().shape({
    plateNumber: Yup.string()
      .matches(alphaNumericRegex, {
        message: 'Please Enter Valid PlateNumber',
        excludeEmptyString: true,
      },
      )
      .required(i18next.t('vehiclePlate.schema.plateNumber') as string),
    plateState: Yup.string().required(i18next.t('vehiclePlate.schema.plateState') as string),
  });

export default schema;
