import { ApiResources, Methods, getRequestHeaders, getUrl, logger } from "./utils";

export type postPlateToVinData = {
  leadId: string;
  plateState: string;
  plateNumber: string;
  assetId?: string;
  contactId: string;
};

export type plateToVinResponse = {
  vin: string;
  make: string;
  model: string;
  year: string;
  isSuccess: boolean;
  isVehicle10YearsOrOlder: boolean;
  isVinFound: boolean;
  message: string;
};

const mapPlateToVinPayload = (payload: postPlateToVinData) => ({
  leadId: payload.leadId,
  plateState: payload.plateState,
  plateNumber: payload.plateNumber,
  assetId: payload.assetId,
  contactId: payload.contactId,
});

export const postPlateToVin = async (payload: postPlateToVinData) => {
  const endpoint = `${getUrl(ApiResources.plateToVin)}`;
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(mapPlateToVinPayload(payload)),
  };

  logger.log('PlaneToVin request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (err) {
    logger.error('PlaneToVin error', err);
    return null;
  }
};
  