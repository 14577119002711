/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
declare var window: any;
const TrustBox = () => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (

        <div id="trustbox" className="container">
            <div className="trustpilot-widget" ref={ref} 
                data-locale="en-US"
                data-template-id="53aa8807dec7e10d38f59f32"
                data-businessunit-id="58af03d90000ff00059d3ac0"
                data-style-height="140px"
                data-style-width="100%"
                data-theme="light"
                data-stars="1,2,3,4,5"
                data-no-reviews="hide"
                data-scroll-to-list="false"
                data-review-languages="en">
            <a href="https://www.trustpilot.com/review/www.ilendingdirect.com" target="_blank" rel="noreferrer">Trustpilot</a>
            </div>
        </div>
    );
};
export default TrustBox;