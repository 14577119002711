import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import VehicleNoVINInfoFormContainer from '../../containers/VehicleNoVINInfoFormContainer';
import Header from '../../components/Header/Header';
import { AppActions } from '../../store/configureStore';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';

export type Props = {
  actions: AppActions;
  vehicleInfo: {
    data: { [key: string]: string | boolean } | null;
    errors: { [key: string]: string };
    loading: boolean;
  };
};

const Title = styled(Typography)`
  margin-bottom: 15px;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.white.main};
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  max-height: 30px;
`;

const StyledSubtitle = styled(Typography)`
  margin-bottom: 15px;
`;

const VehicleNoVINInfo = ({ actions, vehicleInfo: { data } }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const hasVinFailed = useMemo(() => !!data, [data]);
  
  return (
    <>
      <Header />
      <ScreenContainer>
        <Title variant="h1">
          {hasVinFailed ? data?.hasOwnProperty('isSuccess') && data?.isSuccess === false ? t('vehicleNoVin.titlePlateFailed') : t('vehicleNoVin.titleFailed') : t('vehicleNoVin.title')}
        </Title>
        {hasVinFailed && (
          <>
            <div>
              {
                // isSuccess is used to verify whether the response is originating from the VehiclePlate Route.
                data?.hasOwnProperty('isSuccess') && data?.isSuccess === false ? (
                  <StyledButton
                    variant="contained"
                    color="teal"
                    onClick={() => {
                      actions.analyticsTrackClick({ eventType: 'Re Enter PlateToVin Clicked' });
                      actions.clearVehicleInfo();
                      navigate('/vehicle-plate', {
                        replace: true,
                        state: {
                          isReEnterVin: true
                        }
                      });
                    }}
                  >
                    {t('vehicleNoVin.reenterdetail')}
                  </StyledButton>
                ) :
                  <StyledButton
                    variant="contained"
                    color="teal"
                    onClick={() => {
                      actions.analyticsTrackClick({ eventType: 'Re Enter Vin Clicked' });
                      actions.clearVehicleInfo();
                      navigate('/vehicle-info', {
                        replace: true,
                        state: {
                          isReEnterVin: true
                        }
                      });
                    }}
                  >
                    {t('vehicleNoVin.reenter')}
                  </StyledButton>
              }
            </div>
              <StyledSubtitle>{t('vehicleNoVin.subtitle')}</StyledSubtitle>
          </>
        )}
        <VehicleNoVINInfoFormContainer />
      </ScreenContainer>
      <RecaptchaLogo />
    </>
  );
};

export default VehicleNoVINInfo;
