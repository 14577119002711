import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

import ContainerFooter from '../ContainerFooter/ContainerFooter';
import { onInputNumbersOnly } from '../../utils/input';
import CustomButton from '../CustomButton/CustomButton';
import { countryStates } from '../../shared/constants/countryStates';
import AddressHistorySchema from '../../schemas/AddressHistory';
import Logger from '../../libs/Logger';
import { getCurrentDate } from '../../utils/currentDate';
import { AddressData } from '../../services/phoenix';
import { AppActions } from '../../store/configureStore';
import useEffectOnce from '../../utils/useEffectOnce';
import { useMemo } from 'react';

export type Props = {
  actions: AppActions;
  newaddress: {
    errors: { [key: string]: string };
    results: { [key: string]: string };
  };
};

const logger = new Logger('AddressForm');

const AnswerLater = styled.span<{ theme: Theme }>`
  color: ${({ theme }) => theme.palette.teal.main};
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;

  :hover {
    text-decoration: underline;
  }
`;

const RemainingMonths = styled.span<{ theme: Theme }>`
  box-sizing: border-box;
  width: 328px;
  height: 35px;
  background: #fbefcf;
  border: 1px solid #e1ab3f;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 10px 0px;
  color: #181a1a;
`;
const HomeCard = styled(Card)<{ theme: Theme }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.palette.lighterGray.main};
  place-items: center;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const HomeIconButton = styled.span<{ theme: Theme }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 50%;
  margin-left: 17px;
  background-color: ${({ theme }) => theme.palette.white.main};
  height: 58px;
  width: 58px;
`;

const CustomHomeIcon = styled(HomeIcon)<{ theme: Theme }>`
  color: ${({ theme }) => theme.palette.teal.main};
  width: 28px;
  height: 28px;
  pointer-events: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const AddressLine = styled(Typography)<{ theme: Theme }>`
  margin-left: 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.black.main};
`;

const TimeLine = styled(AddressLine)`
  font-size: 12px;
`;

const Address = styled.span`
  display: flex;
  flex-direction: column;
  padding: 2px 0px 17px 15px;
`;

const Addresses = styled.span`
  padding-bottom: 20px;
`;

const MinHistory = styled(Typography)`
  padding: 10px 0px 0px 0px;
`;

const initialValues: AddressData = {
  address: '',
  aptNumber: '',
  city: '',
  state: '',
  zipCode: '',
  moveinDate: '',
  moveoutDate: '',
  residenceType: '',
  monthlyPayment: '',
  contactId: '',
};

//TO DO: get this from the API once endpoint is ready
const addressHistory = [
  {
    line1: '18504 Windy Ridge Rd',
    line2: 'Apt 411',
    city: 'Oklahoma City',
    state: 'OK',
    zipCode: '74447',
    months: 13,
  },
  {
    line1: '18504 Windy Ridge Rd',
    line2: 'Apt 411',
    city: 'Oklahoma City',
    state: 'OK',
    zipCode: '74447',
    months: 8,
  },
];

const AddressHistoryForm = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: AddressHistorySchema,
      onSubmit: values => {
        logger.log(values);
        props.actions.analyticsTrackFormSubmit({
          eventType: 'AddressHistory Form Submit',
          ...values,
        });
        // TO DO: call the proper phoenix method to add new address to history
        // props.actions.phoenixPostAddressHistory(values);
      },
      validateOnMount: true,
    });
  useEffectOnce(() => {
    props.actions.analyticsTrackPage('AddressHistory');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const totalMonths = useMemo(() => 24 - addressHistory.reduce((a, b) => a + b.months, 0), []);

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1.5}>
        <Addresses>
          {addressHistory.map(data => (
            <HomeCard>
              <HomeIconButton>
                <CustomHomeIcon />
              </HomeIconButton>
              <Address>
                <AddressLine>{data.line1}</AddressLine>
                <AddressLine>
                  {data.city}, {data.state} {data.zipCode}
                </AddressLine>
                <AddressLine>{data.line2}</AddressLine>
                <TimeLine>
                  {t('addressForm.form.years', { count: Math.floor(data.months / 12) })} &&nbsp;
                  {t('addressForm.form.months', { count: data.months % 12 })}
                </TimeLine>
              </Address>
            </HomeCard>
          ))}
        </Addresses>
        <Divider />
        <MinHistory variant="h2">{t('addressHistoryForm.minHistory')}</MinHistory>
        <RemainingMonths>
          {t('addressForm.form.youNeed')}
          &nbsp;<strong>{totalMonths < 0 ? 0 : totalMonths}</strong>&nbsp;
          {t('addressForm.form.moreMonths', { count: totalMonths })}
        </RemainingMonths>
        <TextField
          id="address"
          name="address"
          label={t('addressForm.form.address')}
          variant="filled"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.address && !!errors.address}
          helperText={touched.address && errors.address}
          InputProps={{ sx: { bgcolor: 'lighterGray.main' } }}
          sx={{ '& .MuiFilledInput-underline:before': { borderBottomColor: 'darkGray.main' } }}
        />
        <TextField
          id="aptNumber"
          name="aptNumber"
          label={t('addressForm.form.aptNumber')}
          variant="filled"
          value={values.aptNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.aptNumber && !!errors.aptNumber}
          helperText={touched.aptNumber && errors.aptNumber}
          InputProps={{ sx: { bgcolor: 'lighterGray.main' } }}
          sx={{ '& .MuiFilledInput-underline:before': { borderBottomColor: 'darkGray.main' } }}
        />
        <TextField
          id="city"
          name="city"
          label={t('addressForm.form.city')}
          variant="filled"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.city && !!errors.city}
          helperText={touched.city && errors.city}
          InputProps={{ sx: { bgcolor: 'lighterGray.main' } }}
          sx={{ '& .MuiFilledInput-underline:before': { borderBottomColor: 'darkGray.main' } }}
        />
        <FormControl
          variant="filled"
          error={touched.state && !!errors.state}
          sx={{ '& .MuiFilledInput-underline:before': { borderBottomColor: 'darkGray.main' } }}
        >
          <InputLabel id="stateLabel">{t('addressForm.form.state')}</InputLabel>
          <Select
            id="state"
            name="state"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
            labelId="stateLabel"
            sx={{ bgcolor: 'lighterGray.main' }}
          >
            {countryStates.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.state && errors.state}</FormHelperText>
        </FormControl>
        <TextField
          id="zipCode"
          name="zipCode"
          label={t('addressForm.form.zipCode')}
          variant="filled"
          value={values.zipCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.zipCode && !!errors.zipCode}
          helperText={touched.zipCode && errors.zipCode}
          inputProps={{ maxLength: 5 }}
          onInput={onInputNumbersOnly}
          InputProps={{ sx: { bgcolor: 'lighterGray.main' } }}
          sx={{ '& .MuiFilledInput-underline:before': { borderBottomColor: 'darkGray.main' } }}
        />
        <TextField
          id="moveinDate"
          label={t('addressForm.form.moveinDate')}
          type="date"
          variant="filled"
          defaultValue="01/01/2023"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.moveinDate && !!errors.moveinDate}
          helperText={touched.moveinDate && errors.moveinDate}
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: '01/01/2000', max: getCurrentDate() }}
          InputProps={{ sx: { bgcolor: 'lighterGray.main' } }}
          sx={{ '& .MuiFilledInput-underline:before': { borderBottomColor: 'darkGray.main' } }}
        />
        <TextField
          id="moveoutDate"
          label={t('addressForm.form.moveoutDate')}
          type="date"
          variant="filled"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.moveoutDate && !!errors.moveoutDate}
          helperText={touched.moveoutDate && errors.moveoutDate}
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: values.moveinDate }}
          InputProps={{ sx: { bgcolor: 'lighterGray.main' } }}
          sx={{ '& .MuiFilledInput-underline:before': { borderBottomColor: 'darkGray.main' } }}
        />
      </Stack>
      <ContainerFooter>
        <AnswerLater onClick={() => navigate('/lender-info')}>
          {t('addressForm.form.answerLater')}
        </AnswerLater>
        <CustomButton type="submit" isValid={isValid} isSubmitting={isSubmitting}>
          {t('addressForm.form.continueButton')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default AddressHistoryForm;
