import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import VehicleIdFormContainer from '../../containers/VehicleIdFormContainer';
import Header from '../../components/Header/Header';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer';
import RecaptchaLogo from '../../components/RecaptchaLogo/RecaptchaLogo';

const VehicleId = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScreenContainer>
        <Typography variant="h1">{t('vehicleId.title')}</Typography>
        <VehicleIdFormContainer />
      </ScreenContainer>
      <RecaptchaLogo />
    </>
  );
};

export default VehicleId;
