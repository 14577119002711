import { getUrl, getRequestHeaders, logger, ApiResources, Methods, processResponse } from './utils';

export type ContactsData = {
  firstName: '';
  lastName: '';
  email: '';
  phoneNumber: '';
  agreement: false;
  metadata?: { [key: string]: string };
};

export type ContactsResponse =
  | { contactId: string; leadId: string }
  | { message: string; code: string; details: string };

const mapContactsPayload = (payload: ContactsData) => ({
  firstName: payload.firstName,
  lastName: payload.lastName,
  email: payload.email,
  // eslint-disable-next-line no-useless-escape
  cellPhone: payload.phoneNumber.replace(/\+1|[() \-]/g, ''),
  isConsentSelected: payload.agreement,
  metadata: payload.metadata,
});

export const postContact = async (payload: ContactsData): Promise<ContactsResponse | null> => {
  const endpoint = getUrl(ApiResources.contacts);
  const request = {
    method: Methods.post,
    headers: await getRequestHeaders(),
    body: JSON.stringify(mapContactsPayload(payload)),
  };

  logger.log('postContacts request', endpoint, request);
  try {
    const response = await fetch(endpoint, request);
    return processResponse(response);
  } catch (err) {
    logger.error('postContacts error', err);
    return null;
  }
};
