import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ContainerFooter from '../ContainerFooter/ContainerFooter';
import { AppActions } from '../../store/configureStore';
import vehicleInfoSchema from '../../schemas/VehicleInfo';
import vehicleVin from '../../assets/vehicle-vin.png';
import CustomButton from '../CustomButton/CustomButton';
import { useGetVehicleInfo } from '../../utils/useGetVehicleInfo';
import { usePostProgressHistory } from '../../utils/usePostProgressHistory';
import useEffectOnce from '../../utils/useEffectOnce';

export type Props = {
  actions: AppActions;
  state?: {
    isReEnterVin?: boolean
  },
  vehicleInfo: {
    data?: { [key: string]: string };
    errors?: { [key: string]: string };
    loading?: boolean;
  };
  progress?: {
    data: {
      completedScreen: string;
      nextScreen: string;
    };
  };
};

const initialValues = { vin: '' };

const VehicleVinImage = styled.img`
  width: 100%;
  pointer-events: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const VehicleVinLocation = styled.span<{ theme: Theme; hide: boolean }>`
  color: ${({ theme }) => theme.palette.gray.main};
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  padding-bottom: 20px;
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
`;

export const VehicleVinGuide = styled.span<{ theme: Theme }>`
  color: ${({ theme }) => theme.palette.teal.main};
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  text-align: center;

  :hover {
    text-decoration: underline;
  }
`;

const SpacingText = styled.span`
  font-size: 0.75rem;
  line-height: 1.66;
  margin: 3px 14px 0 !important;
  visibility: hidden;
`;

const VehicleInfoForm = ({ actions, vehicleInfo, progress }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const { state } = location;
  const vinRef = useRef<HTMLInputElement>(null);
  const [postProgressHistory] = usePostProgressHistory({
    actions,
    progress: progress?.data,
    screen: 'VehicleInfo',
    nextScreen: 'VehicleNoVin',
    navigateTo: '/vehicle-no-vin',
  });

  const noVinNavigation = () => {
    postProgressHistory();
    actions.analyticsTrackClick({ eventType: "I Don't Know My VIN Clicked" });
  };

  useEffectOnce(() => actions.analyticsTrackPage(`VehicleInfo${state?.isReEnterVin ? 'ReEnterVin' : ''}`));

  const {getVehicleInfoFromVIN} = useGetVehicleInfo({
    actions,
    vehicleInfo,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema: vehicleInfoSchema(),
      onSubmit: values => {
        getVehicleInfoFromVIN(values.vin);
      },
      validateOnMount: true,
    });

  const sanitizeVin = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (vinRef.current) vinRef.current.value = e.target.value.replace(/[^A-Za-z0-9]+/, '');
    handleChange(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1}>
        <VehicleVinImage src={vehicleVin} alt="Your vehicle vin" />
        <TextField
          id="vin"
          name="vin"
          label={t('vehicleInfo.form.vin')}
          variant="filled"
          value={values.vin}
          onChange={sanitizeVin}
          onBlur={handleBlur}
          error={touched.vin && !!errors.vin}
          helperText={touched.vin && errors.vin}
          inputRef={vinRef}
          inputProps={{ sx: { bgcolor: 'lighterGray.main' }, maxLength: 17 }}
          autoFocus
          sx={{
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: 'darkGray.main',
            },
          }}
        />
        <VehicleVinLocation theme={theme} hide={!!touched?.vin && !!errors.vin}>
          {t('vehicleInfo.form.location')}
        </VehicleVinLocation>
        {
          /* keep spacing when trying to click link on first render */
          !touched.vin && <SpacingText>{errors.vin}</SpacingText>
        }
      </Stack>
      <ContainerFooter>
        <VehicleVinGuide onClick={noVinNavigation}>{t('vehicleInfo.form.noVin')}</VehicleVinGuide>
        <CustomButton type="submit" isValid={!!values.vin} isSubmitting={isSubmitting}>
          {t('vehicleInfo.form.continue')}
        </CustomButton>
      </ContainerFooter>
    </form>
  );
};

export default VehicleInfoForm;
