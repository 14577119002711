import * as Yup from 'yup';
import i18next from '../utils/i18n';

const schema = () =>
  Yup.object().shape({
    address: Yup.string().required(i18next.t('address.schema.address') as string),
    zipCode: Yup.string()
      .required(i18next.t('address.schema.zipCode') as string)
      .max(5, i18next.t('address.schema.zipCodeMax') as string),
    city: Yup.string().required(i18next.t('address.schema.city') as string),
    state: Yup.string().required(i18next.t('address.schema.state') as string),
    moveinDate: Yup.string().required(i18next.t('address.schema.moveinDate') as string),
    moveoutDate: Yup.string().required(i18next.t('address.schema.moveoutDate') as string),
  });

export default schema;
