import { bindActionCreators } from 'redux';

import * as Actions from '../actions/actions';
import * as ms from '../store/reducers/index';
import AddressForm, { Props } from '../components/AddressForm/AddressForm';
import { useAppSelector, useAppDispatch } from '../store/hooks';

const AddressFormContainer = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(Actions, dispatch);
  const phoenixRoot = useAppSelector(ms.phoenixSelectors.phoenixRoot);

  const props: Props = {
    newaddress: phoenixRoot.newaddress as Props['newaddress'],
    actions,
  };

  return <AddressForm {...props} />;
};


export default AddressFormContainer;
