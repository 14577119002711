import * as Yup from 'yup';
import i18next from '../utils/i18n';

const schema = () =>
  Yup.object().shape({
    firstName: Yup.string().required(i18next.t('aboutYou.schema.firstName') as string),
    lastName: Yup.string().required(i18next.t('aboutYou.schema.lastName') as string),
    email: Yup.string()
      .email(i18next.t('aboutYou.schema.emailValid') as string)
      .required(i18next.t('aboutYou.schema.email') as string),
    phoneNumber: Yup.string()
      .matches(/\+1 \(\d{3}\) \d{3}-\d{4}/, i18next.t('aboutYou.schema.phoneNumberValid') as string)
      .required(i18next.t('aboutYou.schema.phoneNumber') as string),
    agreement: Yup.boolean().isTrue(''),
  });

export default schema;
